


















































































import { defineComponent } from "@vue/composition-api"
import SpaceObjectLocation from "@/components/SpaceObjectLocation.vue"
import ParsedErrorSpan from "@/components/ParsedErrorSpan.vue"
import SpaceObjectMileage from "./SpaceObjectMileage.vue"
import SpaceObjectCoordinate from "./SpaceObjectCoordinate.vue"
import { spaceObjectTypeFormatter } from "@/services/SpaceObjectsService"
import { ParsedSpaceObject } from "@/utilities/Parser"
export default defineComponent({
    name: "SpaceObjectParsedResultTable",
    components: {
        SpaceObjectLocation,
        ParsedErrorSpan,
        SpaceObjectMileage,
        SpaceObjectCoordinate,
    },
    props: {
        items: Array as () => ParsedSpaceObject[],
    },
    setup() {
        const headers = [
            {
                text: "列",
                sortable: false,
            },
            {
                text: "ID",
                sortable: false,
            },
            {
                text: "所在位置ID",
                sortable: false,
            },
            {
                text: "里程",
                sortable: false,
            },
            {
                text: "編號",
                sortable: false,
            },
            {
                text: "名稱",
                sortable: false,
            },
            {
                text: "分類",
                sortable: false,
            },
            {
                text: "座標",
                sortable: false,
            },
            {
                text: "BIM ID",
                sortable: false,
            },
            {
                text: "標籤",
                sortable: false,
            },
            {
                text: "自定義屬性",
                sortable: false,
            },
        ]
        return {
            headers,
            spaceObjectTypeFormatter,
        }
    },
})
