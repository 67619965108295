


























import { defineComponent, reactive, toRefs, watch } from "@vue/composition-api"
import ParsedSpaceObjectTable from "./ParsedSpaceObjectTable.vue"
import { WorkBookDto } from "@/utilities/XLSXParser"
import {
    ButtonResult,
    closeDialog,
    DialogButtonType,
    errorDialog,
    infoDialog,
    setMessage,
    successDialog,
    warningDialog,
} from "@/services/DialogService"
import {
    spaceObjectTypeFormatter,
    SpaceObjectType,
    SpaceObjectCache,
    putSpaceObjectDisabled,
} from "@/services/SpaceObjectsService"
import {
    collectErrorsFromParsedItems,
    ColumnError,
    ensureSheetExist,
    KnownParsingError,
    openBookFromFile,
    ParsedRemoveSpaceObject,
    ParsingError,
} from "@/utilities/Parser"
import { HTTPError } from "ky"

interface RemoveSpaceObjectImportRow {
    ID?: string
}

const SHEET_NAME = "空間物件清單"

export default defineComponent({
    name: "SpaceObjectImporter",
    components: {
        ParsedSpaceObjectTable,
        ImporterParseErrorTable: () =>
            import("../../components/ImporterParseErrorTable.vue"),
    },
    props: {
        type: {
            type: String as () => SpaceObjectType,
            required: true,
        },
    },
    setup() {
        const state = reactive({
            file: null as File | null,
            items: [] as ParsedRemoveSpaceObject[],
            errors: [] as ParsingError[],
        })

        async function parsePlacesFromBook(book: WorkBookDto) {
            const places = book
                .getSheetByName<RemoveSpaceObjectImportRow>(SHEET_NAME, {
                    raw: false,
                })
                .map((row, rowIndex) => {
                    const errors = [] as ColumnError[]
                    function addError(col: string, err: string) {
                        errors.push({
                            col,
                            err,
                        })
                    }

                    if (!row.ID) addError("ID", "缺漏 ID")

                    const result: ParsedRemoveSpaceObject = {
                        sheet: SHEET_NAME,
                        sourceRow: rowIndex + 2,
                        id: row.ID || undefined,
                        number: undefined,
                        name: undefined,
                        location: undefined,
                        mileage: null,
                        category: null,
                        errors,
                    }
                    return result
                })

            await SpaceObjectCache.updateByIds(
                places.map((item) => item.id).filter((id) => !!id) as string[]
            )

            places.forEach((item) => {
                if (!item.id) return
                const match = SpaceObjectCache.get(item.id)
                if (!match) {
                    item.errors.push({
                        col: "ID",
                        err: "對照到空間物件",
                    })
                    return
                }
                item.location = match
                item.name = match.name
                item.number = match.number
                item.category = match.category
                item.mileage = match.mileage
            })
            return places
        }

        async function parseFile(file: File) {
            try {
                infoDialog("解析中...", "", DialogButtonType.None)
                const book = await openBookFromFile(file)
                ensureSheetExist(book.book, SHEET_NAME)
                const places = await parsePlacesFromBook(book)
                state.items = places
                const placeErrors = collectErrorsFromParsedItems(places)

                state.errors = placeErrors

                closeDialog()
                if (state.errors.length) {
                    errorDialog("解析發生錯誤", "請檢查匯入格式或資料是否正確")
                    return
                }
                successDialog("解析完成")
            } catch (error) {
                closeDialog()
                const msg =
                    error instanceof KnownParsingError
                        ? error.message
                        : "解析過程發生不明錯誤"

                errorDialog(msg)
            }
        }

        watch(
            () => state.file,
            async (file) => {
                if (!file) {
                    state.items = []
                    state.errors = []
                    return
                }
                parseFile(file)
            }
        )

        async function startRemove() {
            const { button } = await warningDialog(
                "確定要註銷這些空間物件？",
                "下層空間物件會移出此物件",
                DialogButtonType.YesNo
            )
            if (button !== ButtonResult.Yes) return
            infoDialog("註銷中，請稍後", "", DialogButtonType.None)
            for (let i = 0; i < state.items.length; i++) {
                const item = state.items[i]
                try {
                    await putSpaceObjectDisabled(item.id!)
                    setMessage(`${i} / ${state.items.length}`)
                } catch (error) {
                    if (
                        error instanceof HTTPError &&
                        error.response.status === 404
                    ) {
                        setMessage(`${i} / ${state.items.length}`)
                        continue
                    }
                    closeDialog()
                    errorDialog(
                        `第${item.sourceRow}列 ID:${item.id} 註銷發生不預期錯誤，請聯絡系統維護人員`,
                        error as any
                    )
                    return
                }
            }
            closeDialog()
            successDialog("註銷完成")
        }

        return {
            ...toRefs(state),
            spaceObjectTypeFormatter,
            startRemove,
        }
    },
})
